import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientFiltrableDataSource, ODataFiltrableDataSource, equal } from 'filtrable-data-source';
import { of, Subject } from 'rxjs';
import { retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DeviceService, DisplayBoDtoNBK, DisplayListBoDtoNBK, DisplayService, LightResourceDtoNBK, RecipeBoDtoNBK, ReducedDeviceDtoNBK, UserKeycloakDtoNBK } from 'src/app/api/nbk';
import { getTimeZone, getUserDisplayMode, KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';
import { MyDisplayFiltersService } from '../my-display-filters/my-display-filters.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayCreationModal } from 'src/app/shared/modals/display-creation-modal/display-creation-modal.component';
import { TwoBtnModalState } from 'src/app/shared/modals/two-btn-modal/two-btn-modal.component';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { DeviceDisplaySelectionModal } from 'src/app/shared/modals/device-display-selection-modal/device-display-selection-modal.component';
import { TableColumn } from '@swimlane/ngx-datatable';
import { DictionaryService } from 'src/app/core/dictionary.service';

@Component({
  selector: 'app-mydisplay-list',
  templateUrl: './my-display-list.component.html',
  styleUrls: ['./my-display-list.component.scss']
})
export class MyDisplayListComponent implements OnInit, OnDestroy {
  datasource = new ODataFiltrableDataSource<DisplayListBoDtoNBK>();

  openedDisplayDetail: Map<number,DisplayBoDtoNBK> = new Map();
  recipesDataSource: Map<number,ClientFiltrableDataSource<RecipeBoDtoNBK>> = new Map();
  devicesDataSource: Map<number,ClientFiltrableDataSource<ReducedDeviceDtoNBK>> = new Map();
  showDetailDeviceTable: Map<number,boolean> = new Map();

  displays$ = new Subject<void>();
  delete$: Subject<DisplayListBoDtoNBK> = new Subject();
  unsubscribe$ = new Subject<void>();

  lang: string;
  timezone: string;

  kcUser: UserKeycloakDtoNBK;

  @ViewChild('lastSyncTemplate', { static: true })
  lastSyncTemplate: ElementRef<HTMLElement>;
  devicesTableColumns: TableColumn[] = [];

  constructor(
    private dictionary: DictionaryService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ts: TranslateService,
    private modalButtonService: ModalService,
    private deviceService: DeviceService,
    private displayService: DisplayService,
    private displayFiltersService: MyDisplayFiltersService,
  ) {
    this.datasource.fetchFunction = (top, skip, count, _orderBy, filter) => {
      return this.displayService.getAllDisplays(
        localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
        top,
        skip,
        count,
        _orderBy,
        filter
      );
    };

    this.datasource.limit = 15;
  }

  ngOnInit(): void {
    this.kcUser = this.route.snapshot.data.listData.loggedUser;
    this.lang = localStorage.getItem(KEY_LANGUAGE_STORAGE)!
    this.timezone = getTimeZone();

    this.devicesTableColumns = [
      {
        prop: 'name',
        name: this.ts.instant('DEVICES.DEVICE_CARD.NAME')
      },
      {
        prop: 'otherIdentifier',
        name: this.ts.instant('DEVICES.DEVICE_CARD.SERIAL_NUMBER')
      },
      {
        prop: 'lastSync',
        name: this.ts.instant('DEVICE.INFO.SYNC'),
        cellTemplate: this.lastSyncTemplate
      }
    ];
    
    this.displays$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.datasource.loadData() )
      )
      .subscribe();

    this.delete$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((res: DisplayListBoDtoNBK) =>
          this.displayService.deleteDisplay(res.id!)
        ),
        tap(() => {
          this.toastr.success(
            this.ts.instant('MY_DISPLAY.DISPLAY_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );

          this.takeAWhileAndReload();

        }),
        retry()
      )
      .subscribe();
    
    this.refreshDisplays();

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onShowDetail( event: any, display: DisplayBoDtoNBK ) {
    if ( !this.openedDisplayDetail.has(display.id!) ) {
      this.displayService
      .getDisplayById(display.id!, localStorage.getItem(KEY_LANGUAGE_STORAGE)!)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.showDetailDeviceTable.set(res.id!, false);
          this.openedDisplayDetail.set(res.id!, res);

          if (!this.recipesDataSource.has(display.id!)) {
            var rDataSource = new ClientFiltrableDataSource<RecipeBoDtoNBK>();
            rDataSource.limit = 5;
            rDataSource.setItems( res.recipes! );
            this.recipesDataSource.set(display.id!,rDataSource);
          } else {
            this.recipesDataSource.get(display.id!)?.setItems(res.recipes!);
          }

          if (!this.devicesDataSource.has(display.id!)) {
            var dDataSource = new ClientFiltrableDataSource<ReducedDeviceDtoNBK>();
            dDataSource.limit = 10;
            dDataSource.setItems( this.getDisplaysDevices(res) );
            this.devicesDataSource.set(display.id!,dDataSource);
          } else {
            this.devicesDataSource.get(display.id!)?.setItems(this.getDisplaysDevices(res));
          }
        })
      ).subscribe();
    }
    
  }

  createNewDisplay() {
    const modalRef = this.modalService.open(DisplayCreationModal, {
      backdrop: 'static',
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.saveDisplay.subscribe((display: DisplayListBoDtoNBK) => {
      this.displayService
      .createDisplay(localStorage.getItem(KEY_LANGUAGE_STORAGE)!,display)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.toastr.success(
            this.ts.instant('MY_DISPLAY.DISPLAY_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
  
          this.takeAWhileAndReload();
        })
      ).subscribe();
    });
  }

  private takeAWhileAndReload() {
    setTimeout(() => {
      this.refreshDisplays();
    }, 500);
  }

  navigateToDisplay(display : DisplayListBoDtoNBK) {
    this.router.navigate(['/', 'my-display', display.id]);
  }

  deleteDisplay(display : DisplayListBoDtoNBK) {
    if ( this.countDevices(display) > 0 ) {
      this.deviceService.getByDisplay(display.id!)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.openDeleteModal(display, 'MY_DISPLAY.DISPLAY_ASSOCIATED_DELETE_MESSAGE', res);
        })
      ).subscribe();
    } else {
      this.openDeleteModal(display, 'MY_DISPLAY.DISPLAY_DELETE_MESSAGE', []);
    }
  }

  openDeleteModal(display: DisplayListBoDtoNBK, message: string, devices: LightResourceDtoNBK[]) {
    const modalInfo: TwoBtnModalState = {
      title: this.ts.instant('MY_DISPLAY.DELETE_DISPLAY'),
      message: this.ts.instant(message, {
          DEVICES_NAME: '&#x2022; ' + devices.map( e => e.name ).join('<br/>&ensp;&ensp;&#x2022; ')
      }),
      confirmBtnClass: 'button-danger',
      confirmBtnText: this.ts.instant('GLOBAL.CONFIRM'),
      cancelBtnClass: 'button-secondary',
      cancelBtnText: this.ts.instant('GLOBAL.UNDO'),
      confirm$: of({})
    };

    this.modalButtonService.showTwoBtnModal(modalInfo).subscribe((r: any) => {
      if (r) {
        this.delete$.next(display);
      }
    });
  }

  assignDevicesToDisplay(display : DisplayListBoDtoNBK) {
    const modalRef = this.modalService.open(DeviceDisplaySelectionModal, {
      backdrop: 'static',
      modalDialogClass: 'modal-xl'
    });
    modalRef.componentInstance.display = display;
    modalRef.componentInstance.saveAssociation.subscribe((ids: number[]) => {
      this.displayService.assignDisplayToDevices(display.id!, { ids: ids})
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.toastr.success(
            this.ts.instant('MY_DISPLAY.DEVICE_ASSIGNED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );

          this.takeAWhileAndReload();
        })
      ).subscribe();
    });
  }

  toggleDevicesInfo (display: DisplayListBoDtoNBK ) {
    if( this.countDevices(display) ) {
      this.showDetailDeviceTable.set(display.id!, !this.showDetailDeviceTable.get(display.id!));
    }
  }

  filtered(val: any) {
    this.displayFiltersService.setCustomFilter(
      this.datasource,
    );
    this.applyMultidisplayFilter();
    this.datasource.applyFilters();
  }

  cleaned() {
    this.displayFiltersService.resetFilter(
      this.datasource,
    );
    this.applyMultidisplayFilter();
    this.datasource.applyFilters();
  }

  refreshDisplays() {
    this.recipesDataSource.clear();
    this.devicesDataSource.clear();
    this.openedDisplayDetail.clear();
    this.showDetailDeviceTable.clear();
    
    this.applyMultidisplayFilter();
    this.datasource.applyFilters();
    this.displays$.next();
  }
  
  private applyMultidisplayFilter() {
    this.datasource.setFilter('mode', equal(getUserDisplayMode(this.kcUser)));
  }

  prevPage( dataSource: ClientFiltrableDataSource<RecipeBoDtoNBK> ) {
    const { page } = dataSource;
    if (page != 0) {
      dataSource.setPage(page - 1);
    } else {
      const totalPages = this.getTotalPages(dataSource);
      dataSource.setPage(totalPages - 1);
    }
  }

  private getTotalPages( dataSource:| ClientFiltrableDataSource<RecipeBoDtoNBK> ) {
    const { limit, totalItems } = dataSource;
    return Math.floor((totalItems! + limit - 1) / limit);
  }

  countDevices(display: DisplayBoDtoNBK | DisplayListBoDtoNBK) {
    return display.devices?.filter( d => d.owner === this.kcUser.id ).length || 0;
  }

  showCookingMode(display: DisplayBoDtoNBK) {
    return display.deviceModel === 'ORACLE'
  }

  private getDisplaysDevices(res: DisplayBoDtoNBK) {
    return res.devices?.filter( d => d.owner === this.kcUser.id )!;
  }

}
