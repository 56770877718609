import {
  Component,
  Input,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { debounceTime } from 'rxjs/operators';
import { RecipeBoDtoNBK } from 'src/app/api/nbk';
import { MyDisplayService } from '../my-display.service';

@Component({
  selector: 'app-display-recipes',
  templateUrl: './display-recipes.component.html',
  styleUrls: ['./display-recipes.component.scss']
})
export class DisplayRecipesComponent implements OnInit {
  @Input() recipes: RecipeBoDtoNBK[] = [];
  @Input() responsiveMode: boolean = false;

  dataSource: ClientFiltrableDataSource<RecipeBoDtoNBK> =
    new ClientFiltrableDataSource();

  filterRecipe: UntypedFormControl = new UntypedFormControl('', []);

  constructor(public myDisplayService: MyDisplayService) {}

  ngOnInit(): void {
    this.dataSource.limit = 8;
    this.dataSource.setItems(this.recipes);

    this.filterRecipe.valueChanges.pipe(debounceTime(500)).subscribe((v) => {
      this.dataSource.setCustomFilter(
        (item) => item.name?.toLowerCase().includes(v.toLowerCase())!
      );
      this.dataSource.applyFilters();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recipes) {
      this.dataSource.setItems(this.recipes);
    }
  }

  onDragRecipesStart() {
    this.myDisplayService.isAlreadyInDisplay = false;
  }

  refreshItems() {
    this.dataSource.setItems(this.recipes);
  }
}
