<div class="display-action-box row py-4" [ngClass]="{ 'nabook-actions': isNabook }">
  <div
    class="col-6 d-flex flex-column justify-content-center align-items-center"
    [dndDropzone]="[]"
    (dndDrop)="onDropDelete($event)"
    dndDragoverClass="custom-drag-over"
    [dndDisableIf]="!myDisplayService.isAlreadyInDisplay"
    [ngClass]="{'disabled': !myDisplayService.isAlreadyInDisplay}"
  >
    <img src="../../../assets/images/delete_recipe.png" />
    <p>{{ 'MY_DISPLAY.REMOVE_RECIPE' | translate}}</p>
  </div>
  <div
    *ngIf="isNabook"
    class="col-6 d-flex flex-column justify-content-center align-items-center"
    [dndDropzone]="[]"
    (dndDrop)="onDropNewFolder($event, cookingMode, true)"
    dndDragoverClass="custom-drag-over"
  >
    <img
      src="../../../assets/images/nabook/new-ml-folder.png"
    />
    <p>{{ 'MY_DISPLAY.ADD_MULTILEVEL_FOLDER' | translate}}</p>
  </div>
  <div
    class="col-6 d-flex flex-column justify-content-center align-items-center"
    [dndDropzone]="[]"
    (dndDrop)="onDropNewFolder($event, cookingMode, false)"
    dndDragoverClass="custom-drag-over"
  >
    <img
      src="../../../assets/images/new-folder.png"
    />
    <p>{{ 'MY_DISPLAY.ADD_FOLDER' | translate}}</p>
  </div>
</div>
