import { Component, Input, OnInit,SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { DisplayBoDtoNBK, FolderBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { getCookingModeList, SelectableCookingMode } from 'src/app/core/utils';
import { MyDisplayService } from '../../my-display.service';
import { DisplayHandler, RECIPES_PER_PAGE } from '../display.handler';

@Component({
  selector: 'app-display-nabook',
  templateUrl: './display-nabook.component.html',
  styleUrls: ['./display-nabook.component.scss']
})
export class DisplayNabookComponent extends DisplayHandler implements OnInit {
  @Input() recipes: RecipeBoDtoNBK[] = [];
  @Input() folders: FolderBoDtoNBK[] = [];
  @Input() multilevelFolders: FolderBoDtoNBK[] = [];
  @Input() cookingMode: DisplayBoDtoNBK.CookingModeEnum;

  dataSourceRecipes: ClientFiltrableDataSource<RecipeBoDtoNBK> =
    new ClientFiltrableDataSource();

  dataSourceFolders: ClientFiltrableDataSource<FolderBoDtoNBK> =
    new ClientFiltrableDataSource();

  dataSourceMultilevelFolders: ClientFiltrableDataSource<FolderBoDtoNBK> =
  new ClientFiltrableDataSource();

  totalPagesRecipes: number;
  totalPagesFolder: number;
  totalPagesMultilevelFolder: number;

  isEmpty: boolean = false;  

  constructor(
    public myDisplayService: MyDisplayService,
    private ts: TranslateService
  ) {
    super(myDisplayService);
  }

  ngOnInit(): void {
    this.dataSourceRecipes.limit = RECIPES_PER_PAGE;
    this.dataSourceFolders.limit = 5;
    this.dataSourceMultilevelFolders.limit = 5;

    this.dataSourceRecipes.setItems(this.recipes);
    this.dataSourceRecipes.setPage(0);
    this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);

    this.dataSourceFolders.setItems(this.folders);
    this.dataSourceFolders.setPage(0);
    this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);

    this.dataSourceMultilevelFolders.setItems(this.multilevelFolders);
    this.dataSourceMultilevelFolders.setPage(0);
    this.totalPagesMultilevelFolder = this.getTotalPages(this.dataSourceMultilevelFolders);

    this.isDisplayEmpty();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recipes) {
      this.dataSourceRecipes.setItems(this.recipes);
      if (
        this.myDisplayService.goToLastPage ||
        this.dataSourceRecipes.items?.length! % this.dataSourceRecipes.limit ===
          0 ||
        this.myDisplayService.resetDisplayRecipe == true
      ) {
        this.myDisplayService.goToLastPage = false;
        this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);
        const newPage =
          this.totalPagesRecipes - 1 < 0 ? 0 : this.totalPagesRecipes - 1;
        this.dataSourceRecipes.setPage(newPage);
      }

      if (this.myDisplayService.resetDisplayRecipe == true) {
        this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);
        this.dataSourceRecipes.setPage(0);
        this.myDisplayService.resetDisplayRecipe = false;
      }
    }

    if (changes.folders) {
      this.dataSourceFolders.setItems(this.folders);
      this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);
      this.dataSourceFolders.setPage(this.totalPagesFolder - 1);

      if (this.myDisplayService.resetDisplayFolder == true) {
        this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);
        this.dataSourceFolders.setPage(0);
        this.myDisplayService.resetDisplayFolder = false;
      }
    }

    if (changes.multilevelFolders) {
      this.dataSourceMultilevelFolders.setItems(this.multilevelFolders);
      this.totalPagesMultilevelFolder = this.getTotalPages(this.dataSourceMultilevelFolders);
      this.dataSourceMultilevelFolders.setPage(this.totalPagesMultilevelFolder - 1);

      if (this.myDisplayService.resetDisplayFolder == true) {
        this.totalPagesMultilevelFolder = this.getTotalPages(this.dataSourceMultilevelFolders);
        this.dataSourceMultilevelFolders.setPage(0);
        this.myDisplayService.resetDisplayMultilevelFolder = false;
      }
    }

    this.isDisplayEmpty();
    
  }

  isDisplayEmpty () {
    this.isEmpty = this.recipes.length === 0 && this.folders.length === 0 && this.multilevelFolders.length === 0;
  }


  getRecipeDatasource() {
    return this.dataSourceRecipes;
  }

  getFolderDatasource() {
    return this.dataSourceFolders;
  }

  getMultilevelFolderDatasource() {
    return this.dataSourceMultilevelFolders;
  }

}
