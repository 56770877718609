import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { MyDisplayService } from '../my-display.service';

export type NewFolderDrop = {
  recipe: RecipeBoDtoNBK,
  multilevel?: boolean
  cookingMode: RecipeBoDtoNBK.CookingModeEnum
}

@Component({
  selector: 'app-display-actions',
  templateUrl: './display-actions.component.html',
  styleUrls: ['./display-actions.component.scss']
})
export class DisplayActionsComponent implements OnInit {
  @Input() isNabook: boolean = false;

  @Input() cookingMode: DisplayBoDtoNBK.CookingModeEnum;

  @Output() onDragEndDeleteRecipes: EventEmitter<RecipeBoDtoNBK> = new EventEmitter();
  @Output() onDragEndNewFolder: EventEmitter<NewFolderDrop> = new EventEmitter();

  constructor(public myDisplayService: MyDisplayService) { }

  ngOnInit(): void {
  }

  onDropDelete(event: any) {
    this.onDragEndDeleteRecipes.emit(event.data);
  }

  onDropNewFolder(event: any, cookingMode: RecipeBoDtoNBK.CookingModeEnum, multilevel: boolean) {
    const data: NewFolderDrop =  {
      recipe: event.data,
      multilevel: multilevel,
      cookingMode: cookingMode
    }
    this.onDragEndNewFolder.emit(data);
  }

}
